<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='goBack'/>
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <van-collapse-item :title="$t('pur.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('pur.申请单号') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.code }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.业务类型') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.bussinessType }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.作废状态') }}</h5>
          <p>{{ this.ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商') }}(报关公司)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.supplierName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商英文名称') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.supplierUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.实际供应商') }}(厂商)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.thirdCompanyName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.实际供应商英文名称') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.thirdCompanyUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否境外采购') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.isPurAbroad }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购币种') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.currency }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.公司指导汇率') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.guideRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.核算汇率') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售业务员') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.salesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否外采') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.isOutsourc }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.区域') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.area }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购业务员') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.purSalesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购部门') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.purDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.商机号') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.businessNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.客户号') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.clientNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请总代金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.totalAgentAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.批复总代金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.approvedTotalAgentAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.特别折扣批复号') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.discountApprovalNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售合同号') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.saleContractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备注') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.remarks }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.作废原因') }}</h5>
          <p>{{ this.ruleForm.deleteReason }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.销售信息')" name="2" v-show="ruleForm.bussinessType === '1'">
        <div class="items">
          <h5>{{ $t('pur.二级代理商') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.secondAryAgent }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.二级代理商英文名称') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.secondAryUsAgent }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.账期') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.accPeriod }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.endUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户英文名称') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.endUserUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户所在地区') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.userArea }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请二代金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.secondAgentAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请最终用户金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.endUserAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户行业') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.userIndustry }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.批复二代金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.approvedSecondAgentAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.批复最终用户金额') }}(原币)</h5>
          <p>{{ this.ruleForm.purApplicationHeader.approvedUserAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户行业细分') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.userIndustryInfo }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.产品配置清单')" name="3">
        <div v-for="(item, index) in this.ruleForm.purApplicationHeader.applicationLineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.产品名称') }}:</h5>
            <p>{{ item.commodityDetails}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类别') }}:</h5>
            <p>{{ item.commodityCategoryName}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品编码') }}:</h5>
            <p>{{ item.commodityCode}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.规格型号') }}:</h5>
            <p>{{ item.materialSpecs}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品组') }}:</h5>
            <p>{{ item.productGroup | setDict('productGroup')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品线') }}:</h5>
            <p>{{ item.productLine | setDict('PRODUCTLINE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}:</h5>
            <p>{{ item.productType| setDict('PRODUCT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}-二级分类:</h5>
            <p>{{ item.secondProductType | setDict('PRODUCT_SECOND_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.单位') }}:</h5>
            <p>{{ item.unitNameCn}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.数量') }}:</h5>
            <p>{{ item.quantity}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.厂商报价总价') }}(原币):</h5>
            <p>{{ item.firmPrice}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.折扣率') }}:</h5>
            <p>{{ item.discountRate}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.折扣金额') }}(原币):</h5>
            <p>{{ item.discountAmount}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.折扣后金额') }}(原币):</h5>
            <p>{{ item.discountAfterAmount}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.批复金额') }}(原币):</h5>
            <p>{{ item.approvedAmount}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.成本价未税') }}(人民币):</h5>
            <p>{{ item.costAmountExcludtax}}</p>
          </div>
          <div class="items">
            <h5>VAT(增值税):</h5>
            <p>{{ item.valueAddedTax}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.成本价含税') }}(人民币):</h5>
            <p>{{ item.costAmount}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.关联采购合同')" name="4">
        <div v-for="(item, index) in this.purContList" :key="index" style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('pur.采购合同号') }}:</h5>
            <p style='text-decoration-line: underline' @click="gotoBusinessPage('pur/purCooperative/purCooperativeView', item)">{{ item.contractNo}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.原币金额') }}:</h5>
            <p>{{ item.amountUsd}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.对人民币汇率') }}:</h5>
            <p>{{ item.exchangeRateRmb}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.人民币金额') }}:</h5>
            <p>{{ item.amountRmb}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.关联销售合同')" name="5">
        <div v-for="(item, index) in this.saleContList" :key="index"
             style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('pur.销售合同号') }}:</h5>
            <p style='text-decoration-line: underline' @click="gotoBusinessPage('sale/trade/saleTradeContractEdit', item)">{{ item.contractNo}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.订单类型') }}:</h5>
            <p>{{ item.contractType | setDict('SALE_TRADE_CONTRACT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.状态') }}:</h5>
            <p>{{ item.status | setDict('SALE_CONTRACT_STATUS')}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.系统信息')" name="6">
        <div class="items">
          <h5>{{ $t('pur.制单日期') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.制单人') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.修改时间') }}</h5>
          <p>{{ this.ruleForm.purApplicationHeader.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
    import {Dialog} from 'vant';
    import util from '../../../libs/util';
    import myHistoryPanel from '@/views/business/components/myHistoryPanel';
    import myApproval from '@/views/business/components/myApproval';

    export default {
        name: 'visitorFieldComponent',
        components: {
            myHistoryPanel,
            myApproval
        },
        data() {
            return {
                loading: false,
                purContList: [],
                saleContList: [],
                finished: false,
                activeNames: ['1', '2', '3', '4', '5'],
                hostName: '',
                task: '',
                personId: '',
                approvalShow: false,
                customSubmitBtnName: ['同意', '不同意'],
                id: '',
                key: '',
                title: '',
                detail: null,
                active: 0,
                businessKey: '',
                woCode: 'purApplicationAbolish',
                processInstanceName: '特价申请作废',
                mappingId: '',
                taskInstanceId: '',
                currentNode: '',
                variables: {},
                taskType: '',
                ruleForm: {
                    purApplicationHeader: {
                        id: '',
                        code: '',
                        supplierName: '',
                        supplierId: '',
                        thirdSupplierId: '',
                        thirdCompanyName: '',
                        exchangeRateRmb: '',
                        guideRate: '',
                        bussinessType: '',
                        saleContractNo: '',
                        status: 'PREPARING',
                        version: 0,
                        isUse: 'N',
                        isOutsourc: 'N',
                        isPurAbroad: 'N',
                        isCreditControl: '',
                        currency: '',
                        accPeriod: '',
                        area: '',
                        productGroup: '',
                        productLine: '',
                        purSalesMan: '',
                        userIndustry: '',
                        userIndustryInfo: '',
                        userArea: '',
                        contactInfo: '',
                        remarks: '',
                        salesMan: '',
                        salesManId: '',
                        purDeptId: '',
                        secondAryAgent: '',
                        secondAryAgentId: '',
                        secondAryUsAgent: '',
                        endUserName: '',
                        endUserId: '',
                        endUserUsName: '',
                        totalAgentAmount: 0,
                        secondAgentAmount: 0,
                        endUserAmount: 0,
                        processInstanceId: '',
                        applicationLineList: []
                    },
                    id: '',
                    orgId: '',
                    deptId: '',
                    orderStatus: '',
                    processInstanceId: '',
                    status: '',
                    deleteReason: ''

                },
            };
        },
        props: {},
        methods: {
            goBack () {
                let pageType = this.$router.currentRoute.query.type;
                this.$router.push({
                    name: 'workFlow/workFlowList',
                    'query': {
                        'type': pageType
                    }
                });
            },
            gotoBusinessPage (name, item) {
                this.$router.push({
                    'name':  name,
                    'params': {
                        'row':  item,
                        'pageType': 'view'
                    },
                    'query': {
                        'businessKey': item.businessKey,
                        'type': 'transated',
                        'row':  JSON.stringify( item ),
                        'pageType': 'view',
                        't': new Date().getTime()
                    }
                });
            },
            getProcessMappingId() {
                var userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
                    data: {
                        entity: {
                            woCode: this.woCode,
                            orgId: userInfo.companyId,
                            deptId: userInfo.deptId
                        }
                    }
                }).then(res => {
                    if (res.data.ext.code === '2000') {
                        this.mappingId = res.data.ext.mappingId;
                    } else {
                        Toast.fail({
                            message: res.data.ext.message,
                            type: 'error',
                            duration: 1500
                        });
                    }
                });
            },
            backfilleditData(id) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/PurApplicationHeaderAbolish/view',
                    data: {
                        entity: {
                            id: id
                        }
                    }
                }).then(res => {
                    // 成功回调方法
                    var data = res.data.entity;
                    if (data) {
                        let _this = this;
                        _this.ruleForm = res.data.entity;
                        _this.ruleForm.purApplicationHeader.status = util.setDict(_this.ruleForm.purApplicationHeader.status, 'PUB_WF_STATUS');
                        _this.ruleForm.purApplicationHeader.endStatus = util.setDict(_this.ruleForm.purApplicationHeader.endStatus, 'FINAL_STATUS');
                        _this.ruleForm.purApplicationHeader.bussinessType = util.setDict(_this.ruleForm.purApplicationHeader.bussinessType, 'PUR_BUSINESS_TYPE');
                        _this.ruleForm.purApplicationHeader.isAdvPayment = util.setDict(_this.ruleForm.purApplicationHeader.isAdvPayment, 'PUB_Y_N');
                        _this.ruleForm.purApplicationHeader.fundMode = util.setDict(_this.ruleForm.purApplicationHeader.fundMode, 'FUND_MODE');
                        _this.ruleForm.purApplicationHeader.paymentMethod = util.setDict(_this.ruleForm.purApplicationHeader.paymentMethod, 'PAYMENT_METHOD');
                        _this.ruleForm.purApplicationHeader.isPurAbroad = util.setDict(_this.ruleForm.purApplicationHeader.isPurAbroad, 'PUB_Y_N');
                        _this.ruleForm.purApplicationHeader.productGroup = util.setDict(_this.ruleForm.purApplicationHeader.productGroup, 'productGroup');
                        _this.ruleForm.purApplicationHeader.productCategories = util.setDict(_this.ruleForm.purApplicationHeader.productCategories, 'PRODUCTCLASSIF');
                        _this.ruleForm.purApplicationHeader.productLine = util.setDict(_this.ruleForm.purApplicationHeader.productLine, 'PRODUCTLINE');
                        _this.ruleForm.purApplicationHeader.isOutsourc = util.setDict(_this.ruleForm.purApplicationHeader.isOutsourc, 'PUB_Y_N');
                        _this.ruleForm.purApplicationHeader.outSourceType = util.setDict(_this.ruleForm.purApplicationHeader.outSourceType, 'CLASSIFICATION_OPTION');
                        _this.ruleForm.purApplicationHeader.receivingWay = util.setDict(_this.ruleForm.purApplicationHeader.receivingWay, 'RECEIVING_WAY');
                        _this.ruleForm.purApplicationHeader.userIndustry = util.setDict(_this.ruleForm.purApplicationHeader.userIndustry, 'HANGYE');
                        _this.ruleForm.purApplicationHeader.userIndustryInfo = util.setDict(_this.ruleForm.purApplicationHeader.userIndustryInfo, 'HANGYE_MINGXI');
                        _this.ruleForm.purApplicationHeader.area = util.setDict(_this.ruleForm.purApplicationHeader.area, 'PUR_AREA');
                        _this.ruleForm.purApplicationHeader.invoiceType = util.setDict(_this.ruleForm.purApplicationHeader.invoiceType, 'SALE_INVOICE_TYPE');
                        _this.ruleForm.purApplicationHeader.currency = util.setDict(_this.ruleForm.purApplicationHeader.currency, 'SPECIAL_CURRENCY');
                        _this.processInstanceName = util.getWorkflowCode(_this.ruleForm.purApplicationHeader.code, _this.processInstanceName);
                        _this.variables.activitiData = res.data.entity;
                        _this.getProcessMappingId();
                        _this.findPurList();
                        _this.findSaleList();
                        _this.$refs.historyPanel.getData(_this.ruleForm.processInstanceId);
                        if (_this.$route.query.type) {
                            _this.approvalShow = true;
                        }
                    }
                });
            },
            setApprovalData(row) {
                if (row && row.pid) {
                    this.ruleForm.id = row.businessKey;
                    this.taskInstanceId = row.id;
                    this.taskType = row.taskType;
                }
            },
            findPurList() {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/purCooperativeHeader/appFindPurData',
                    data: {
                        entity: {
                            code: this.ruleForm.code
                        }
                    }
                }).then(res => {
                    var data = res.data.entity;
                    this.purContList = data;
                });
            },
            findSaleList() {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/sale/contract/trade/saleContractOrder/appFindSaleData',
                    data: {
                        entity: {
                            code: this.ruleForm.purApplicationHeader.code,
                            id: this.ruleForm.purApplicationHeader.id
                        }
                    }
                }).then(res => {
                    var data = res.data.entity;
                    this.saleContList = data;
                });
            },
            /**
             * 业务提交之前校验
             * */
            checkBeforeProcess(type) {
                if (type) {
                    if (type === 'beforeAddsign') {
                        this.$refs.demoTemperatureEdit.beforeAddsign();
                    } else if (type === 'afterAddsign') {
                        this.$refs.demoTemperatureEdit.afterAddsign();
                    } else if (type === 'endProcess') {
                        this.$refs.demoTemperatureEdit.endProcess();
                    } else if (type === 'repelProcess') {
                        this.repelProcess();
                    } else {
                        if (!this.taskInstanceId) {
                            var params = {
                                businessKey: this.ruleForm.id,
                                mappingId: this.mappingId,
                                processInstanceName: this.processInstanceName,
                                userName: sessionStorage.getItem('userName'),
                                variables: this.variables
                            };
                            this.startProcessAction(params);
                        } else {
                            this.$refs.demoTemperatureEdit.submit();
                        }
                    }
                } else {
                    Toast.fail({
                        message: this.$t('pur.数据校验失败'),
                        type: 'warning'
                    });
                }
            },
            // 业务发起流程
            startProcessAction(params) {
                if (params.mappingId) {
                    params.mappingId = this.mappingId;
                }
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.purApplicationHeader.code, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/pur/PurApplicationHeaderAbolish/start',
                    data: params
                })
                    .then(res => {
                        var backData = res.data.ext;
                        if (backData) {
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                            this.$refs.demoTemperatureEdit.isStart = true;
                            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
                            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
                            this.$refs.demoTemperatureEdit.isFirstNode = true;
                            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
                            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
                            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
                        } else {
                            Toast.fail({
                                message: this.$t('pur.流程发起异常'),
                                type: 'warning'
                            });
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                        }
                    })
                    .catch(_ => {
                        this.$refs.demoTemperatureEdit.submitLoading = false;
                    });
            },
            // 业务提交流程
            submitProcessAction(params) {
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.purApplicationHeader.code, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/pur/PurApplicationHeaderAbolish/submit',
                    data: params
                }).then(res => {
                    if (res.data.ext.success) {
                        this.processCallback(null, null, this.taskType);
                    } else {
                        Toast.fail({
                            message: res.data.ext.msg,
                            type: 'warning'
                        });
                    }
                })
                    .catch(_ => {
                    });
            },
            /***
             * 业务撤销流程
             */
            repelProcess() {
                var _this = this;
                _this
                    .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
                        cancelButtonClass: 'sysBackBtn'
                    })
                    .then(_ => {
                        this.$myHttp({
                            method: 'post',
                            url: '/microarch/pur/PurApplicationHeaderAbolish/undoProcess',
                            data: {
                                entity: {
                                    id: this.ruleForm.id,
                                    processInstanceId: this.ruleForm.processInstanceId
                                }
                            }
                        })
                            .then(res => {
                                if (res.data.success === undefined || res.data.success) {
                                    Toast.fail({
                                        message: this.$t('pur.撤销成功'),
                                        type: 'success'
                                    });
                                    this.processCallback(null, null, this.taskType);
                                } else {
                                    Toast.fail({
                                        message: res.data.msg,
                                        type: 'warning'
                                    });
                                }
                            })
                            .catch(_ => {
                            });
                    })
                    .catch(_ => {
                    });
            },

            /***
             * 关闭发起流程审批窗口
             * 删除流程实例，回滚业务状态
             */
            closeStartCallback(pid) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/PurApplicationHeaderAbolish/deleteProcess',
                    data: {
                        entity: {
                            id: this.ruleForm.id,
                            processInstanceId: pid
                        }
                    }
                })
                    .then(res => {
                        this.$refs.demoTemperatureEdit.dialogVisible = false;
                        this.$refs.demoTemperatureEdit.taskId = '';
                        this.$refs.demoTemperatureEdit.processInstanceId = '';
                        this.processCallback(null, null, this.taskType);
                    })
                    .catch(_ => {
                    });
            },

            /**
             * 提交之前校验
             * */
            beforeSubmitCallBack(params) {
                // 业务参数校验 此时可以从params中获取提交审批所有的参数
                // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
                // 不通过给出相应的提示或者触发其他业务操作
                // let selectUserArray = params.candidateUserIds[params.nextId]
                // console.log(selectUserArray);
                this.submitProcessAction(params);
            },
            /**
             * 流程审批之后业务数据更改
             * */
            processCallback(piid, optionType, taskType) {
                var _this = this;
                // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
                // // 成功回调方法
                // if (taskType === 'transated') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/haveList'
                //   });
                // } else if (taskType === 'waitfor') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/toDoList'
                //   });
                // } else {
                //   this.$router.push({
                //     name: 'sale/return/saleReturnApplyList'
                //   });
                //   this.$bus.emit('saleReturnApply-SaveSuccess');
                // }
                this.$router.go(-1);
            }
        },
        created() {
        },
        mounted() {
            let selectRow = JSON.parse(this.$route.query.row);
            if (selectRow) {
                this.ruleForm.id = selectRow.id;
                if (selectRow.businessKey) {
                    this.ruleForm.id = selectRow.businessKey;
                }
            }
            if (this.ruleForm.id) {
                this.purContList = [];
                this.saleContList = [];
                this.backfilleditData(this.ruleForm.id);
            }

            if (selectRow) {
                this.setApprovalData(selectRow);
            }
        },
        filters: {
            setDict(v, dictName) {
                return util.setDict(v, dictName);
            }
        }
    };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
